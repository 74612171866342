import React, { useEffect } from "react";

export default function HomePage() {
  useEffect(() => {
    const elem = document.getElementById("HomePage");
    elem.style.opacity = 1;
  }, []);

  let style = {
    width: "100%",
    height: "100%",
    backgroundImage: "url(/background.jpg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity: "0",
    transitionDuration: "1s",
  };

  return <div id="HomePage" style={style} />;
}

import React from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";

export default function MenuBar() {
  return (
    <div id="MenuBar">
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="md"
        bg="light"
        data-bs-theme="light"
      >
        <Navbar.Brand href="/">
          <img id="Logo" src="/tr6.svg" alt="Home" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navbar" />
        <Navbar.Collapse id="main-navbar">
          <Nav className="mr-auto">
            {window.NAVIGATION.map((menu) => (
              <NavDropdown title={menu.name} key={menu.name}>
                {menu.items.map((item) => (
                  <NavDropdown.Item key={item.href} href={item.href}>
                    {item.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

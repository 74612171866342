import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import MenuBar from "./MenuBar";
import HomePage from "./HomePage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

export default function App() {
  return (
    <div id="App">
      <BrowserRouter>
        <MenuBar />
        <div id="Body">
          <Routes>
            <Route path="/" element={<HomePage />} />
            {window.NAVIGATION.map((menu) =>
              menu.items.map((item) => (
                <Route
                  path={item.href}
                  key={item.href}
                  element={
                    <iframe
                      title={item.name}
                      src={item.path}
                      width="100%"
                      height="98%"
                    />
                  }
                />
              ))
            )}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}
